function underline(s) {
    return s.replace(/_(\w)/g, function (all, letter) {
        return letter.toUpperCase()
    })
}
function humpline(s) {
    return s.replace(/([A-Z])/g, '_$1').toLowerCase()
}
function jsonNewline(obj) {
    if (obj instanceof Array) {
        obj.forEach(function (v) {
            jsonNewline(v)
        })
    } else if (obj instanceof Object) {
        Object.keys(obj).forEach(function (key) {
            let newKey = underline(key)
            if (newKey !== key) {
                obj[newKey] = obj[key]
                delete obj[key]
            }
            jsonNewline(obj[newKey])
        })
    }
}
function jsonline(obj) {
    if (obj instanceof Array) {
        obj.forEach(function (v) {
            jsonline(v)
        })
    } else if (obj instanceof Object) {
        Object.keys(obj).forEach(function (key) {
            let newKey = humpline(key)
            if (newKey !== key) {
                obj[newKey] = obj[key]
                delete obj[key]
            }
            jsonline(obj[newKey])
        })
    }
}
export default {
    jsonNewDate: function (obj) {
        jsonNewline(obj)
    },
    jsonNewLineData: function (obj) {
        jsonline(obj)
    },
}
