import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
import { cleanLoginInfo, getToken } from "./auth"
import tooles from "../utils/tools/format"
import { messageError, messageWarning } from './message'
// import { cleanNullItem } from "../utils/util"

const BASE_URL = "https://xrmsx9y3lc.execute-api.us-west-2.amazonaws.com/prod/"
// const BASE_URL = "http://localhost:8000/"

// create an axios instance
const service = axios.create({
    baseURL: BASE_URL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        config.headers['Authorization'] = getToken()
        // }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        // return Promise.reject(error)
    }
)

// 请求参数处理
service.interceptors.request.use(request => {
    if (request.params) { // get请求参数
        let temdata = JSON.parse(JSON.stringify(request.params)) // 防止深拷贝跟浅拷贝
        tooles.jsonNewLineData(temdata)
        request.params = temdata
    } if (request.data) { // post请求参数
        let temdata = JSON.parse(JSON.stringify(request.data)) // 防止深拷贝跟浅拷贝
        tooles.jsonNewLineData(temdata)
        request.data = temdata
    }
    return request
}, (error) => {
    return Promise.reject(error);
})

// 错误处理
service.interceptors.response.use(resp => {
    return resp
},
    err => {
        if (err.response){
            if (err.response.status == 400){
                messageWarning("参数错误！")
            }else if (err.response.status == 401){
                messageWarning("token失效，请重新登陆！！！")
                cleanLoginInfo();
                location.reload();
            }else if (err.response.status == 403){
                messageWarning("权限不足，不能访问该页面")
            }else if (err.response.status == 500){
                console.log("服务器错误");
                messageError("服务器错误！！！")
            }else {
                console.log("请求失败");
                console.log(err);
                messageError("请求失败！")
            }
        }
        return Promise.reject(err)
    })

// 返回参数处理
service.interceptors.response.use(resp => {  // 该处为后端返回整个内容
    tooles.jsonNewDate(resp.data);
    return resp
}), (error => {
    return Promise.reject(error);
})

export default service
